import { Flex, IconButton, Text, useDisclosure, useTheme } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import { responsive } from 'contexts/responsive';
import { IoMenu } from 'react-icons/io5';
import Menu from './Menu';



const Header = ({ siteTitle, ...props }) => {
  const ref = useRef()
  const [toTop, setDistance] = useState()
  // useEffect(() => {
  //   const scroll = document.querySelector('.header')
  //   scroll.addEventListener('scroll', () => {
  //     if (scroll.scrollTop > 0) {
  //       setDistance(scroll.scrollTop)
  //     }
  //   })
  // } ,[])
  const { isOpen, onOpen, onClose } = useDisclosure()
  // console.log(toTop, ref)
  return (
    <Flex
      as="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      justifyContent="space-between"
      pb={responsive('1em', '2.875em')}
      pt={responsive('1.625em', '2.875em')}
      px={responsive('1.375em', '3.125em')}
      zIndex="docked"
      height="header"
      bg={responsive("rgba(255,255,255,0.5)", 'transparent')}
      ref={ref}
      {...props}
    >
      <Text fontSize={responsive('1em', '1.5em')}>候選人財產</Text>
      <IconButton onClick={onOpen} variant="transparent" fontSize={responsive('1.5em', '2em')} icon={<IoMenu size="1em" />} />
      <Menu isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default Header
