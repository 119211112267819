import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { responsive } from "contexts/responsive";
import breakpoints from "contexts/responsive/breakpoints";
import get from "lodash/get";

const font = '"Open Sans", Arial, "Noto Serif TC", "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  mono: "Menlo, monospace",
  noto: "Noto Sans TC",
  serif: "Noto serif TC"
}

const colorScheme = 'black';

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    text: get(theme.colors, 'black'),
    skyBlue: '#77BBD2',
    bgGray: '#E1E2DE',
    filterBg: '#F3EDE8',
    bgPurple: '#5D579E',
    darkPink: '#E1B6BC',
    darkPurple: '#494484',
    paleGray: '#EAEAEA',
    heavyGreen: '#002424',
    deepGray: '#CDCDCD',
    others: '#DC88B7',
    noneBg: '#F5F5F5',
    kmt: '#76BBD2',
    npp: '#EBEB69',
    dpp: '#6BBD75',
    tmd: '#CBE6E6',
    pfp: '#DA5445',
    lightOthers: '#F8C8E3',
    lightNoneBg: '#D4D4D4',
    lightKmt: '#BADFED',
    lightNpp: '#E1D141',
    lightDpp: '#BCDAC2',
    lightTmd: '#ABDFDF',
    lightPfp: '#F49E98',
    btnOthers: '#712A43',
    btnNoneBg: '#030000',
    btnKmt: '#205869',
    btnNpp: '#695520',
    btnDpp: '#0A2323',
    // btnTmd: '#0A2323',
    btnPfp: '#5A1C15',
    textGray: '#6A6A6A',
    lineColor: '#89897B',
  },
  breakpoints: createBreakpoints(breakpoints),
  sizes: {
    header: '4em',
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme
      },
      baseStyle: {
        // px: '0.75em',
      },
      variants: {
        transparent: {
          bg: 'transparent',
          color: 'black',
          p: '0',
          height: 'auto',
          minWidth: 'auto',
          fontSize: responsive('0.75em', '0.875em'),
        },
        outline: {
          bg: 'transparent',
          color: 'black',
          borderRadius: 'full',
          py: '0.375em',
          height: 'auto',
          fontWeight: 400,
          fontSize: responsive('0.75em', '1.125em'),
          fontFamily: 'noto',
          px: '1em',
        },
        solid: {
          bg: 'black',
          color: 'white',
          borderRadius: 'full',
          fontSize: responsive('0.75em', '1.125em'),
          fontWeight: 500,
          fontFamily: 'noto',
          minWidth: 'auto',
          px: '1em',
          py: '0.375em',
          height: 'auto'
        },
        purple: {
          bg: '#494484',
          color: 'white',
          borderRadius: responsive('0.5rem 0.5rem 0 0', '1.375rem 1.375rem 0 0'),
          fontSize: responsive('0.75em', '1.5em'),
          fontWeight: 500,
          fontFamily: 'noto',
          minWidth: 'auto',
          px: responsive('0.75rem', '2rem'),
          py: responsive('0.375rem', '0.875rem'),
          height: 'auto'
        },
        heavyGreen: {
          bg: 'var(--chakra-colors-heavyGreen)',
          color: 'white',
          fontWeight: 500,
          minWidth: 'auto',
          height: 'auto'
        },
      }
    },
    Container: {
      baseStyle: {
        maxW: 'container.lg',
        px: { base: '1.375em', md: '3.875em' }
      },
    },
  },
  colorScheme,
  styles: {
    global: {
      html: {
        bg: 'skyBlue'
      }
    }
  }
}

const customTheme = extendTheme(overrides)

export default customTheme
