import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
} from '@chakra-ui/react'
import Button from 'components/Button'
import { responsive } from 'contexts/responsive'
import { StaticImage } from 'gatsby-plugin-image'

const buttons = [
  {
    btn: '專案設計說明',
    href: 'https://whatthedata.cc/blog/%e5%80%99%e9%81%b8%e4%ba%ba%e8%b2%a1%e7%94%a2-%e8%a8%ad%e8%a8%88%e8%aa%aa%e6%98%8e/'
  },
  {
    btn: '分享到Facebook',
    href: 'https://www.facebook.com/sharer/sharer.php?u=https://citycandidate.whatthedata.cc/'
  },
  {
    btn: '聯繫團隊',
    href: 'https://whatthedata.cc/about/'
  },
]


const Menu = ({ isOpen, onClose }) => {
  return (
    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent width={responsive('15.5em', '31em')} maxWidth="auto" px={responsive('4.375em', '8.5rem')} style={{ width: 'auto' }}>
          <DrawerCloseButton />
          <DrawerHeader textAlign={'center'} mt={responsive('6.25rem')} pb="0.5rem" fontSize={responsive('0.875em', '1.875em')} borderBottom="1px solid" borderColor="heavyGreen">候選人財產</DrawerHeader>
          <DrawerBody px="0" pt={responsive('0.625em', '3.75em')}>
            {buttons.map(({ btn, href }, i) => (
              <Box key={i}>
                <Button
                  width="100%"
                  borderRadius="none"
                  variant="transparent"
                  href={href}
                  py={responsive('0.625rem', '1.5rem')}
                  borderBottom="1px solid"
                  borderColor="heavyGreen"
                  color="black"
                  fontSize={responsive('0.75em', '1.125em')}
                >
                  {btn}
                </Button>
              </Box>
            ))}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="transparent" hred="https://relab.cc" width={responsive('7rem', '10rem')}>
              <StaticImage src="relab.png" />
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
  )
}

export default Menu
